/*simple text page*/
.simple-text-page {
  color: @dark-blk;
  font-size: 16px;
  line-height: 24px;
  .mb(80);

  p {
    color: @dark-blk;
    font-size: 16px;
    line-height: 24px;
  }

  h2 {
    .demi;
    .mb(15);
    .mt(25);
    font-size: 30px;
  }

  h3 {
    .mb(15);
    .mt(25);
    font-size: 26px;
    line-height: 32px;
    font-style: italic;
  }
}


/*other-foto-list*/
.calatori-albume-page {
  .blue-clr {
    color: @blue;
  }

  .light-clr {
    color: @light-clr;
  }

  .listing-description {
    h2 {
      .mb(1);
    }

    p {
      .small-font;
    }

    a {
      font-size: 13px;
      line-height: 18px;
    }
  }
}


.add-albume-calatori {
  border: 1px solid @blue;

  img {
    .pos-rel;
    top: 2px;
  }
}


/*single angajare page*/
.angajare-contacti {
  .form-group {
    width: 100%;

    input {
      padding-left: 16px;

      &[type="checkbox"] {
        padding-left: 0px;
      }
    }

    span {
      .ml(16);
    }
  }

  label[for="file"] {
    .average-font;
    padding: 7px 24px;
    .border-radius(5px);
    border: 1px solid @blue;
    color: @blue;
    .mb(16);
    .ml(0);
    width: fit-content;
    font-family: 'Futura PT N';
  }

  label[for="checkbox"] {
    span {
      .average-font;
    }
  }
}


/*single calatorie album foto*/
.small-flower-img {
  .mb(6);
  width: calc(100% - 6px);
}

.small-flower {
  margin: 0px;

  img {
    .border-radius(5px);
  }
}

.big-flower {
  margin: 0px;
  .mb(6);
  width: calc(100% - 6px);

  img {
    .border-radius(5px);
  }
}

.spune {
  width: calc(100% - 6px);
}


/*other-foto-add page*/

.col-brdr {
  // border: 1px dashed @blue;
  width: calc(100% - 28px);
  .ml(14);
  .border-radius(5px);
  background: url('../../../img/album/dashed-border.png') no-repeat;
  background-size: 100% 100%;
}

.centeralize {
  .pos-rel;
  top: 68%;
  transform: translateY(-50%);
  min-height: 245px;
}

.aduaga-album-foto-page {
  .box-offer {
    .container-offer {
      padding: 17px 14px;
    }
  }

  .listing-description {
    h2 {
      .demi;
      font-size: 17px;
      line-height: 22px;

      &.aduaga-title {
        font-size: 24px;
        line-height: 31px;
        font-family: 'Futura PT';
      }

      &.normal {
        font-family: 'Futura PT N';
      }
    }
  }

  form {
    .form-group {
      .mt(-6);
    }
  }
}


/*despre noi page*/
.page-despre-noi {
  p {
    color: @dark-blk;
  }
}

.feature-banner {
  .border-radius(5px);
}

.badges-img {
  width: 112px;
  height: 112px;
  .border-radius(50%);
  border: 1px solid @green;
  text-align: center;
  line-height: 105px;

  img {
    width: 55px;
  }
}

.badges {
  width: 220px;
  text-align: center;
}

figcaption {
  h3 {
    color: @green;
    font-size: 18px;
    line-height: 23px;
    .demi;
    .mb(0);
  }

  p {
    min-height: 42px;
    font-size: 16px;
    line-height: 21px;
  }
}

.carousel-control-next {
  opacity: 1;
  width: 24px;

  img {
    transform: rotate(270deg);
    .pos-abs;
    right: 0;
  }
}

.carousel-control-prev {
  opacity: 1;
  width: 24px;

  img {
    .pos-abs;
    left: 0;
    transform: rotate(90deg);
  }
}


.travel-briefcase {
  width: 270px;
}

.travel-info {
  //width: 238px;
  .pos-rel;

  li {
    a {
      font-size: 24px;
      line-height: 31px;
      .demi;
      color: @black;
    }

    &.ft-mail {
      a {
        font-family: 'Futura PT N';
      }
    }
  }

  .ft-tel, .ft-mail {
    padding-left: 40px;

    &::before {
      width: 30px;
      height: 30px;
      background-size: 30px 30px;
    }
  }
}

.page-contact {
  h2 {
    font-size: 24px;
    line-height: 31px;
    .demi;
  }
}


/*partaje-cerari*/
.page-partaje-cereri {
  .supra-title {
    font-size: 20px;
    text-align: center;
  }

  table {
    thead {
      display: none;
    }

    tr {
      .mb(15);
      display: block;
      border-bottom: 1px solid @light-brdr;

      td {
        font-size: 16px;
        line-height: 21px;
        display: block;
        width: 100%;
        border-bottom: none !important;
        padding: 0px;

        :nth-child(1) {
          font-family: 'Futura PT M';
        }

        a {
          font-size: 18px;
          line-height: 23px;
          color: #393939 !important;

          &:hover {
            color: #FD9704 !important;
          }
        }

        &:last-child {
          a {
            font-size: 16px;
            line-height: 21px;
            font-family: 'Futura PT N';
            color: @orange;
          }

          padding-top: 10px;
          padding-bottom: 15px;
        }
      }
    }
  }

  label[for="filter"] {
    width: 100%;

    select {
      .ml(0);
      width: 100%;
      outline: none;

      &:focus, &:hover, &:active {
        border: 1px solid @orange;
      }
    }
  }
}


/* landing page city break*/
.landing-city-break {
  .sale-offer {
    span {
      font-size: 12px;
    }
  }
}

.country-list {
  columns: 2;

  li {
    margin-bottom: 10px;

    a {
      text-transform: capitalize;
      color: @black;

      &:hover {
        color: @orange;

        i {
          color: @orange;
        }
      }

      i {
        color: @black;
        .pos-rel;
        top: 2px;
      }
    }

  }

  &.sub-country {
    columns: 1;

    li {
      .mb(5);
    }
  }
}

.sub-citybreak {
  .mb(27);

  img {
    .border-radius(5px 0px 0px 5px);
    height: 328px;
    width: 100%;
    object-fit: cover;
  }
}

.carousel-div-oferta {
  .carousel-control-prev, .carousel-control-next {
    align-items: center;
    width: 32px;
    bottom: 0px;
    opacity: 1;
    background: white;
    top: auto;
    height: 100px;

    i {
      color: @blue;
      font-size: 27px;
    }
  }
}

.carousel-div-hotels {
    .carousel-control-prev, .carousel-control-next {
        align-items: center;
        width: 32px;
        bottom: 0px;
        opacity: 1;
        background: white;
        top: auto;
        height: 100px;

        i {
            color: @blue;
            font-size: 27px;
        }
    }
}

.carousel-div-albume {
  max-width: 96%;
  margin: auto;

  .carousel-control-prev {
    left: -32px;
  }

  .carousel-control-next {
    right: -32px;
  }

  .carousel-control-prev, .carousel-control-next {
    align-items: center;
    width: 47px;
    bottom: 0px;
    opacity: 1;
    //background: #F9FCFF;
    top: auto !important;
    height: 100%;

    i {
        color: #ffffff;
        font-size: 27px;
        padding: 5px;
        -webkit-box-shadow: 0px 0px 29px -9px rgb(0 0 0 / 75%);
        -moz-box-shadow: 0px 0px 29px -9px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 29px -9px rgb(0 0 0 / 75%);
        background: #0f0f0f6b;
    }
  }
}

@media (max-width: 576px) {
    .mt-sm-4 {
      margin-top: 1.5rem; /* sau orice valoare dorită */
    }
    .w-button{
        width: 30%;
    }
}
@media (min-width: 576px) {
    .w-button{
        width: 20%;
    }
}
.album-foto-carousel {
  .carousel-indicators {
    position: unset;
    .mb(0);
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 0px 20px rgba(11, 59, 167, 0.1);
    display: inline-block;
    white-space: nowrap;
    background: white;
    overflow-x: scroll;
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;

    [data-bs-target] {
      .mt(4);
      padding: 1px;
      width: 100px !important;
      height: 88px;
      border: none;
      margin-left: 0px;
      margin-right: 0px;
      opacity: 0.3;

      &.active {
        opacity: 1;
        background: none;
      }

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .carousel-control-prev, .carousel-control-next {
    align-items: center;
    width: 32px;
    bottom: 0px;
    opacity: 1;
    background: white;
    top: auto;
    height: 100px;

    i {
      color: @blue;
      font-size: 27px;
    }
  }

  .carousel-item {
    max-height: 170px;
    height: 170px;

    img {
      object-fit: cover;
      height: 100%;
      .border-radius(5px 5px 0px 0px);
    }
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.se-pre-con {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #fffffff2;

  img {
    width: 100px;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    position: absolute;
    animation: spin 2s infinite linear;
  }
}

.img-loading {
  //width: 100%;
  max-width: 200px;
  display: block;
  margin: auto;
  animation: spin 2s infinite linear;
}
