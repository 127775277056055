/*foto single album*/
.big-flower{
    margin: 10px;
    width: calc(100% - 20px);

    img{
        width: calc(100% - 10px) !important;
    }
}

.small-flower-img{
    width: calc(100% - 10px) !important;
    .mb(10);
}
.small-flower{
    margin: 10px;
}

.aduaga-album-foto-page {
    .listing-description{
        h2{
            font-size: 20px;
            line-height: 26px;
        }
    }
}
.centeralize{
    top: 55%;
}


.badges-img{
    width: 164px;
    height: 164px;
    line-height: 160px;
    img{
        width: 81px;
    }
}
.travel-briefcase{
    width: 90%;
}


/*country listing*/
.sub-citybreak{
    img{
        height: 229px;
    }
}
.sub-citybreak{
    .listing-description{
        h2{
            font-size: 18px;
            line-height: 23px;
        }
    }
}
.country-list{
    li{
        a{
            font-size: 16px;
            line-height: 21px;
        }
    }
}
.album-foto-carousel{
    .carousel-item{
        max-height: 250px;
        height: 250px;
        img{
            .border-radius(0px 5px 0px 0px);
        }
    }
}