.main-heading{
    &.calatori-albume{
        .mb(15);
    }
}
.col-brdr{
    width: calc(50% - 20px);
    .mt(-6);
    .ml(5);
}
.centeralize{
    top: 50%;
}
.aduaga-album-foto-page{
    .listing-description{
        h2{
            &.aduaga-title{
                font-size: 20px;
                line-height: 26px;
                .mb(0);
            }
        }
    }
}

.badges{
    width: 204px;
}

.travel-briefcase{
    width: 100%;
}


/*partaje cerari*/
.page-partaje-cereri{
    table{
        thead{
            display: contents;
        }
        tr{
            .mb(0);
            display: table-row;
            border-bottom: 0;

            th{
                display: table-cell;
            }
            td{
                border-bottom: 1px solid @light-brdr !important;
                display: table-cell;
                width: auto;
                padding: 9px;
            }
        }
    }
    label[for="filter"]{
        select{
            .ml(10);
            width: 167px;
        }
    }
}


/*country-listing*/
.sub-citybreak{
    img{
        height: 220px;
    }
}
.album-foto-carousel{
    .carousel-item{
        max-height: 350px;
        height: 350px;
    }
}
