.calatori-albume-page{
        .listin-description{
            a{
                font-size: 14px;
            }
        }
}


/*partaje cerari*/
.page-partaje-cereri{
    .supra-title{
        font-size: 24px;
        line-height: 31px;
        text-align: left;
    }
    table{
        tr{
            td{
                a{
                    font-size: 18px;
                    line-height: 23px;
                }
            }
        }
    }
}
