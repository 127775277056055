.add-albume-calatori{
    border: none;
}

.calatori-albume-page{
    .listing-description{
        h2{
            .mb(12);
        }
    }
}


.sub-citybreak{
    img{
        height: 200px;
    }
    .listing-description{
        h2{
            font-size: 17px;
            line-height: 21px;
        }
    }

}
.country-list{
    li{
        a{
            font-size: 14px;
            line-height: 17px;
        }
    }
}
.album-foto-carousel{
    .carousel-item{
        max-height: 225px;
        height: 225px;
    }
}
