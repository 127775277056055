.sub-citybreak{
    img{
        height: 239px;
    }
}
.album-foto-carousel{
    .carousel-item{
        max-height: 350px;
        height: 350px;
    }
}
